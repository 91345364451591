<template>
  <div class="panel">
    <div class="panel-content">
      <el-form
        class="more-filter"
        label-position="left"
        label-width="120px"
        v-show="moreFilterVisible"
      >
        <el-form-item label="报备渠道">
          <el-autocomplete
            v-model="queryData.organ"
            :fetch-suggestions="(k, c) => autocompleteFetch(k, c, 2)"
            clearable
            placeholder="全称、简称"
            @select="e => search(e, 'organs')"
            @clear="search"
          >
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="报备人员">
          <el-autocomplete
            v-model="queryData.user"
            :fetch-suggestions="(k, c) => autocompleteFetch(k, c, 3)"
            clearable
            placeholder="姓名、电话"
            @select="e => search(e, 'users')"
            @clear="search"
          >
            <template v-slot="{ item }">
              <div class="autocomplete-item-label">{{ item.value }}</div>
              <div class="autocomplete-item-value" :title="item.sub">
                {{ item.sub }}
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="到访次数">
          <el-select
            placeholder="选择"
            v-model="queryData.visitNumList"
            multiple
            collapse-tags
            clearable
            @change="search"
          >
            <el-option
              v-for="item of conditionMap.visitCounts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户判定">
          <el-select
            placeholder="选择"
            multiple
            clearable
            v-model="queryData.determineStarList"
            @change="search"
          >
            <el-option
              v-for="item of conditionMap.customRates"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="置业顾问">
          <el-select
            placeholder="选择"
            filterable
            clearable
            v-model="queryData.consultantId"
            @change="search"
          >
            <el-option
              v-for="item of conditionMap.consultants"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报备状态">
          <el-select
            placeholder="选择"
            clearable
            v-model="queryData.reportState"
            @change="search"
          >
            <el-option
              v-for="item of conditionMap.statuses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="带看奖励">
          <el-radio-group v-model="queryData.visitPrize" @change="search">
            <el-radio-button
              v-for="item of conditionMap.visitAwardStatuses"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="filter-bar">
        <div class="filter">
          <el-input
            class="w300 f-search-bar"
            placeholder="单号、姓名、电话"
            v-model="queryData.keyword"
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
          <div class="group">
            <el-select v-model="dateType" @change="getReportList">
              <el-option :value="1" label="报备时间"></el-option>
              <el-option :value="2" label="到访时间"></el-option>
              <el-option :value="3" label="带看奖发放时间"></el-option>
            </el-select>
            <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              unlink-panels
              :picker-options="pickerOptions"
              @change="search"
            >
            </el-date-picker>
          </div>
          <el-select
            class="select"
            v-model="queryData.estate"
            placeholder="报备项目"
            filterable
            clearable
            @change="search"
          >
            <el-option
              v-for="item in conditionMap.estates"
              :value="item.id"
              :label="item.name"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button-group class="actions-btn">
          <f-table-fields
            :name="`reportVisit${tab}`"
            :options="defaultFields"
            @change="onFieldsChange"
          >
            <el-button icon="iconfont icon-list"></el-button>
          </f-table-fields>
          <el-button
            icon="iconfont icon-refresh"
            @click="getReportList"
          ></el-button>
          <el-button
            icon="iconfont icon-clean"
            @click="clearQueryData"
          ></el-button>
          <el-button
            icon="iconfont icon-filter"
            @click="moreFilterVisible = !moreFilterVisible"
          ></el-button>
          <el-button
            icon="iconfont icon-export"
            @click="exportExcel"
          ></el-button>
        </el-button-group>
      </div>
      <f-table
        ref="table"
        class="table"
        row-key="rowKey"
        lazy
        border
        :data="list"
        :load="loadHistoryReportList"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :columns="tableColumns"
        :row-style="rowStyle"
        :summary-method="getSummaries"
        show-summary
        @sort-change="onTableSort"
      ></f-table>
    </div>
    <f-pagination
      shadow
      :name="`reportList${tab}`"
      :fixed="false"
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      :page-sizes="[5, 10, 20, 30, 40, 50, 100, 200, 500]"
      @change="getReportList"
    ></f-pagination>
  </div>
</template>

<script>
import ExportJsonExcel from "js-export-excel";
import storage from "@/utils/storage";
import { QRCODE_GENERATE_URL } from "@/config";
import {
  getReportListV2,
  getReportListConditionV2,
  getReportSameListV2
} from "@/api/distribution";
import { datePickerShortcuts } from "@/utils/component-utils";

const queryData = {
  // 接待的置业顾问ID集合
  consultantId: null, //【前端临时用】
  consultantIds: [],
  // NO_STAR（D+）、ONE_STAR（C类）、TWO_STAR（B类）、THREE_STAR（A类）、INVALID(D),可用值:INVALID,ONE_STAR,TWO_STAR,THREE_STAR,NO_STAR
  determineStarList: [],
  // 结束接待时间
  endDetermineDate: "",
  // 带看奖发放时间
  endVisitPrizeDate: "",
  // 带看结束时间
  endVisitTime: "",
  // 项目筛选
  estate: null, //【前端临时用】
  estates: [],
  // 单号、姓名、电话
  keyword: "",
  // 渠道id
  organ: null, //【前端临时用】
  organs: [],
  pageNum: 1,
  pageSize: storage.get("reportListPageSize") || 10, // 默认10条
  // 平台ID
  platformId: null,
  // 报备结束时间
  reportEndTime: "",
  // 报备开始时间
  reportStartTime: "",
  // 0-待审核 1-报备成功 2-已带看 3-未通过 4-待判客 5-待确客 8-已取消 20 有效带看 21 无效带看
  reportState: null,
  // sortField: "",
  // 排序字段：1：报备时间 2：到访时间 3:带看奖发放时间 4: 客户接待 11 带看奖励
  sortFieldNo: null,
  // 排序 : 0 desc 1 asc
  sortType: null,
  // sortTypeStr: "",
  // 开始接待时间
  startDetermineDate: "",
  // 带看奖发放时间
  startVisitPrizeDate: "",
  // 带看开始时间
  startVisitTime: "",
  // 1 处理中 2 已带看 3 未带看
  tab: null,
  // 报备人员
  user: null, //【前端临时用】
  users: [],
  // 带看阶段 1: 首看 2: 2看 3:2+
  visitNumList: [],
  // 是否发带看奖 true false
  visitPrize: ""
};
const statuses = [
  { label: "待审核", value: 0, tabs: [1] },
  { label: "报备成功", value: 1, tabs: [3, 1] },
  { label: "未通过", value: 3, tabs: [] },
  { label: "已取消", value: 8, tabs: [3] },
  { label: "待判客", value: 4, tabs: [1] },
  { label: "待确客", value: 5, tabs: [1] },
  { label: "有效带看", value: 20, tabs: [2] },
  { label: "无效带看", value: 21, tabs: [2] }
];
export default {
  props: {
    tab: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: datePickerShortcuts()
      },
      // 1报备时间 2 到访时间
      dateType: 1,
      date: [],
      // 筛选条件
      conditionMap: {
        estates: [],
        organs: [],
        consultants: [],
        visitCounts: [
          { label: "首看", value: 1 },
          { label: "2看", value: 2 },
          { label: "2看+", value: 3 }
        ],
        customRates: [
          { label: "A", value: "THREE_STAR" },
          { label: "B", value: "TWO_STAR" },
          { label: "C", value: "ONE_STAR" },
          { label: "D", value: "INVALID" },
          { label: "D+", value: "NO_STAR" }
        ],
        visitAwardStatuses: [
          { label: "不限", value: "" },
          { label: "未发放", value: false },
          { label: "已发放", value: true }
        ],
        statuses: this.$deepClone(statuses)
      },
      visitCountColorMap: {
        1: "#89a2bf",
        2: "#97db29"
      },
      customRateColorMap: {
        THREE_STAR: "#faa72c",
        TWO_STAR: "#4b94f2",
        ONE_STAR: "#45bc2c",
        INVALID: "#89a2bf",
        NO_STAR: "#9399a5"
      },
      // 0-待审核 1-报备成功 2-已带看 3-未通过 4-待判客 5-待确客 8-已取消 20 有效带看 21 无效带看
      statusMap: {
        0: {
          label: "待审核",
          color: "#fa8c16"
        },
        1: {
          label: "报备成功",
          color: ""
        },
        2: {
          label: "已带看",
          color: "#52c41a"
        },
        3: {
          label: "未通过",
          color: "#f5222d"
        },
        4: {
          label: "待判客",
          color: "#fa8c16"
        },
        5: {
          label: "待确客",
          color: "#fa8c16"
        },
        8: {
          label: "已取消",
          color: "#999999"
        },
        20: {
          color: "#52c41a",
          label: "有效带看"
        },
        21: {
          label: "无效带看",
          color: "#999999"
        }
      },
      reportStates: [
        {
          id: 0,
          name: "待审"
        },
        {
          id: 1,
          name: "通过"
        },
        {
          id: 2,
          name: "已带看"
        },
        {
          id: 3,
          name: "不通过"
        }
      ],
      queryData: this.$deepClone(queryData),
      moreFilterVisible: false,
      list: [],
      total: 0,
      // 是否为聚合平台报备
      isPlatform: false,
      // 需要展示的字段
      checkedFields: [],
      // 默认字段选项
      defaultFields: [
        [
          {
            label: "单号",
            prop: "reportCode",
            checked: true,
            disabled: true
          },
          {
            label: "状态",
            prop: "reportState",
            checked: true,
            disabled: true
          },
          {
            label: "项目",
            prop: "estateName",
            checked: true,
            disabled: true
          }
        ],
        "报备信息",
        [
          {
            label: "客户姓名",
            prop: "customerName",
            checked: true,
            disabled: false
          },
          {
            label: "客户电话",
            prop: "customerTelephone",
            checked: true,
            disabled: false
          },
          {
            label: "预计到访",
            prop: "planVisitStr",
            checked: false,
            disabled: false
          },
          {
            label: "报备人",
            prop: "reportUserName",
            checked: true,
            disabled: false
          },
          {
            label: "报备人电话",
            prop: "reportUserTelephone",
            checked: false,
            disabled: false
          },
          {
            label: "报备渠道",
            prop: "company",
            checked: true,
            disabled: false
          },
          {
            label: "报备时间",
            prop: "inDate",
            checked: true,
            disabled: false
          },
          {
            label: "备注信息",
            prop: "remark",
            checked: false,
            disabled: false
          }
        ],
        "带看信息",
        [
          {
            label: "到访时间",
            prop: "visitTime",
            checked: true,
            disabled: false
          },
          {
            label: "到访次数",
            prop: "visitNum",
            checked: true,
            disabled: false
          },
          {
            label: "客户判定",
            prop: "determineStar",
            checked: true,
            disabled: false
          },
          {
            label: "置业顾问",
            prop: "determineConsultantName",
            checked: true,
            disabled: false
          },
          {
            label: "凭证附件",
            prop: "visitCertificateJsonArr",
            checked: false,
            disabled: false
          },
          {
            label: "客户白描",
            prop: "determineRemark",
            checked: false,
            disabled: false
          },
          {
            label: "带看奖励",
            prop: "visitCashPrize",
            checked: true,
            disabled: false
          },
          {
            label: "发放日期",
            prop: "visitPrizeDate",
            checked: true,
            disabled: false
          },
          {
            label: "带看码",
            prop: "visitQrCode",
            checked: false,
            disabled: false
          }
        ]
      ]
    };
  },
  computed: {
    tableColumns() {
      const defaultColumns = [
        {
          label: "单号",
          prop: "reportCode",
          width: "150",
          renderTag: "span",
          render: ({ row }) => (
            <el-button type="text" onClick={() => this.onRowClick(row)}>
              <span
                style={{
                  color: row.reportState === 8 ? "#999" : ""
                }}
              >
                {row.reportCode}
              </span>
            </el-button>
          )
        },
        {
          label: "状态",
          prop: "reportState",
          render: ({ row }) => {
            const { statusMap } = this;
            let color = statusMap[row.reportState]?.color;
            let stateName = statusMap[row.reportState]?.label;
            if (row.reportState === 2) {
              stateName = row.confirmVisitValid ? "有效带看" : "无效带看";
              color = row.confirmVisitValid ? "#52c41a" : "#999999";
            }
            return (
              <div class="report-state">
                <span class="badge" style={{ background: color }}></span>
                <span style={{ color }}>{stateName}</span>
              </div>
            );
          }
        },
        {
          label: "项目",
          prop: "estateName"
        },
        {
          label: "客户姓名",
          prop: "customerName"
        },
        {
          label: "客户电话",
          prop: "customerTelephone",
          render: ({ row }) => this.$format.phone(row.customerTelephone)
        },
        {
          label: "预计到访",
          prop: "planVisitStr",
          placeholder: "-",
          render: ({ row }) => {
            return (
              <span>
                {this.$format.date(row.planVisitTime)} {row.planVisitStr}
              </span>
            );
          }
        },
        {
          label: "报备人",
          prop: "reportUserName"
        },
        {
          label: "报备人电话",
          prop: "reportUserTelephone",
          render: ({ row }) => this.$format.phone(row.reportUserTelephone)
        },
        {
          label: "报备渠道",
          prop: "company",
          render: ({ row }) => {
            return row.naturalVisits === 1
              ? "自然到访"
              : row.company
              ? row.company
              : "-";
          }
        },
        {
          label: "报备时间",
          prop: "inDate",
          sortable: "custom",
          render: ({ row }) => this.$format.date(row.inDate, 16)
        },
        {
          label: "备注信息",
          prop: "remark",
          placeholder: "-"
        },
        {
          label: "到访时间",
          prop: "visitTime",
          sortable: "custom",
          render: ({ row }) => this.$format.date(row.visitTime, 16) || "-"
        },
        {
          label: "到访次数",
          prop: "visitNum",
          render: ({ row }) => {
            return row.visitNum ? (
              <span
                class="color-badge"
                style={{
                  background: this.visitCountColorMap[row.visitNum]
                }}
              >
                {row.visitNum === 1 ? "首看" : row.visitNum + "看"}
              </span>
            ) : (
              "-"
            );
          }
        },
        {
          label: "客户判定",
          prop: "determineStar",
          placeholder: "-",
          render: ({ row }) => {
            return row.determineStar ? (
              <span
                class="color-badge"
                style={{
                  background: this.customRateColorMap[row.determineStarEnum]
                }}
              >
                {row.determineStar}
              </span>
            ) : (
              "-"
            );
          }
        },
        {
          label: "置业顾问",
          prop: "determineConsultantName",
          placeholder: "-"
        },
        {
          label: "凭证附件",
          prop: "visitCertificateJsonArr",
          render: ({ row }) => {
            return (
              <div class="file-image-list">
                {row.visitCertificateJsonArr?.map(src => (
                  <el-image
                    fit="cover"
                    src={src}
                    preview-src-list={row.visitCertificateJsonArr}
                  ></el-image>
                )) || "-"}
              </div>
            );
          }
        },
        {
          label: "客户白描",
          prop: "determineRemark",
          placeholder: "-"
        },
        {
          label: "带看奖励",
          prop: "visitCashPrize",
          sortable: "custom",
          render: ({ row }) => {
            return row.visitCashPrize ? `¥${row.visitCashPrize}` : "-";
          }
        },
        {
          label: "发放日期",
          prop: "visitPrizeDate",
          sortable: "custom",
          render: ({ row }) => {
            return this.$format.date(row.visitPrizeDate, 16);
          }
        },
        {
          label: "带看码",
          prop: "visitQrCode",
          render: ({ row }) => {
            return (
              (row.visitQrCode || row.otherQrCode) && (
                <el-button class="visit-code-btn" type="text">
                  带看码
                  <el-image
                    class="image"
                    src={
                      row.visitQrCode
                        ? `${QRCODE_GENERATE_URL}${row.visitQrCode}`
                        : row.otherQrCode
                    }
                    preview-src-list={[
                      row.visitQrCode
                        ? `${QRCODE_GENERATE_URL}${row.visitQrCode}`
                        : row.otherQrCode
                    ]}
                  ></el-image>
                </el-button>
              )
            );
          }
        }
      ];
      const result = [];
      for (const field of this.checkedFields) {
        const columnIndex = defaultColumns.findIndex(c => c.prop === field);
        if (columnIndex > -1) {
          result.push(defaultColumns[columnIndex]);
          // 储存后就移除 减少下次循环次数
          defaultColumns.splice(columnIndex, 1);
        }
      }
      return result;
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.initData();
            this.getConsultants();
            this.getEstates();
            this.getReportList();
          });
        }
      }
    },
    tab: {
      immediate: true,
      handler(tab) {
        this.checkedFields = storage.get(
          `reportVisit${this.tab}TableFields`,
          []
        );
        if (!tab) return;
        this.conditionMap.statuses = statuses.filter(s => s.tabs.includes(tab));
      }
    }
  },
  methods: {
    exportExcel() {
      const historyFields = storage.get(`reportVisit${this.tab}TableFieldData`);
      console.log(historyFields);
      const fields = this.$deepClone(historyFields || this.options)
        .filter(item => Array.isArray(item))
        .flat()
        .filter(item => {
          if (
            item.prop === "visitQrCode" ||
            item.prop === "visitCertificateJsonArr"
          ) {
            return false;
          }
          return item.checked;
        });
      console.log(fields);
      const fileName = `报备带看_${this.list.length}条_${this.$format.date(
        Date.now(),
        17
      )}`;
      const sheetData = this.list.map(row => {
        let stateName = this.statusMap[row.reportState]?.label;
        if (row.reportState === 2) {
          stateName = row.confirmVisitValid ? "有效带看" : "无效带看";
        }
        return {
          ...row,
          reportState: stateName,
          inDate: this.$format.date(row.inDate, 16),
          visitTime: this.$format.date(row.visitTime, 16),
          visitPrizeDate: this.$format.date(row.visitPrizeDate, 16),
          visitNum: row.visitNum
            ? row.visitNum === 1
              ? "首看"
              : row.visitNum + "看"
            : "",
          visitCertificateJsonArr: row.visitCertificateJsonArr?.join(","),
          visitQrCode: row.visitQrCode || row.otherQrCode
        };
      });
      const option = {
        fileName,
        datas: [
          {
            sheetData,
            sheetName: "报备带看",
            sheetFilter: fields.map(i => i.prop),
            sheetHeader: fields.map(i => i.label),
            columnWidths: new Array(fields.length).fill(10)
          }
        ]
      };
      const toExcel = new ExportJsonExcel(option); //new
      toExcel.saveExcel(); //保存
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总价";
          return;
        }
        if (column.property === "visitCashPrize") {
          const values = data.map(item => Number(item[column.property]));
          if (values.some(value => !isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = `¥${sums[index]}`;
          } else {
            sums[index] = "-";
          }
        }
      });
      return sums;
    },
    rowStyle({ row, rowIndex }) {
      if (row.reportState === 8) {
        return { color: "#999999" };
      }
    },
    onFieldsChange({ checkedFields }) {
      this.checkedFields = checkedFields;
    },
    initData() {
      queryData.pageSize = storage.get(`reportList${this.tab}PageSize`) || 10;
      this.queryData = this.$deepClone(queryData);
      this.queryData.tab = this.tab;
    },
    // 获取置业顾问
    async getConsultants() {
      const res = await getReportListConditionV2({
        // conditionType 1 项目 2 渠道 3 报备人员 4 置业顾问
        conditionType: 4
      });
      this.conditionMap.consultants = res?.filter(i => i) || [];
    },
    // 获取报备项目
    async getEstates(keyword, cb) {
      const res = await getReportListConditionV2({
        // conditionType 1 项目 2 渠道 3 报备人员 4 置业顾问
        conditionType: 1
      });
      this.conditionMap.estates = res || [];
    },
    // 筛选条件搜索
    async autocompleteFetch(keyword, cb, conditionType) {
      keyword = keyword?.trim() || "";
      if (!keyword) {
        cb([]);
        return;
      }
      const res = await getReportListConditionV2({
        // conditionType 1 项目 2 渠道 3 报备人员 4 置业顾问
        conditionType,
        keyword
      });
      cb(
        res?.map(item => {
          return {
            value: item.name || item.label,
            id: String(item.id),
            sub: item.value
          };
        }) || []
      );
    },
    // 获取历史记录列表
    async loadHistoryReportList(row, treeNode, resolve) {
      const res = await getReportSameListV2({ reportId: row.id });
      resolve(
        res?.map(item => {
          return {
            ...item,
            // 这里rowKey如果继续使用id可能跟父级id重复 导致table row-key重复引起bug
            // 目前id暂时无用，使用随机数代替
            rowKey: String(Math.random()).replace(".", "")
          };
        }) || []
      );
    },
    // 获取报备列表
    async getReportList() {
      const queryData = this.$deepClone(this.queryData);
      const [startDate, endDate] = this.date || [];
      // 报备日期或者带看日期
      if (this.dateType === 1) {
        queryData.reportStartTime = startDate;
        queryData.reportEndTime = endDate;
      } else if (this.date === 2) {
        queryData.startVisitTime = startDate;
        queryData.endVisitTime = endDate;
      } else {
        queryData.startVisitPrizeDate = startDate;
        queryData.endVisitPrizeDate = endDate;
      }
      // 处理为后端需要的数据
      queryData.consultantIds = queryData.consultantId
        ? [queryData.consultantId]
        : [];
      queryData.users = queryData.user ? queryData.users : [];
      queryData.organs = queryData.organ ? queryData.organs : [];
      queryData.estates = queryData.estate ? [queryData.estate] : [];
      delete queryData.consultantId;
      delete queryData.user;
      delete queryData.organ;
      delete queryData.estate;
      const res = await getReportListV2(queryData);
      if (res) {
        this.list = res.list.map(item => {
          return {
            ...item,
            rowKey: item.id,
            hasChildren: !!item.sameReportCount
          };
        });
        this.total = res.total;
      }
    },
    // 搜索
    search(e, type) {
      if (type === "organs" || type === "users") {
        this.queryData[type] = [e.id];
      }
      this.queryData.pageNum = 1;
      this.getReportList();
    },
    // 排序
    onTableSort({ column, prop, order }) {
      // 排序字段：1：报备时间 2：到访时间 3:带看奖发放时间 4: 客户接待 11 带看奖励
      const orderMap = {
        ascending: 1,
        descending: 0
      };
      const propMap = {
        inDate: 1,
        visitTime: 2,
        visitPrizeDate: 3,
        visitCashPrize: 11
      };
      this.queryData.sortType = orderMap[order];
      this.queryData.sortFieldNo = order ? propMap[prop] : null;
      this.getReportList();
    },
    // 点击条目
    onRowClick(row) {
      this.$emit("orderNumberClick", row);
    },
    // 清空筛选条件
    clearQueryData() {
      this.dateType = 1;
      this.date = [];
      this.queryData = this.$deepClone(queryData);
      this.queryData.tab = this.tab;
      this.$refs.table.$refs.table.clearSort();
      this.search();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-table {
    .el-table__row--level-1 {
      background-color: #f5f5f5;
    }
  }
}
.panel {
  height: 100%;
  @include flex-y;
  &-content {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
  }
}
.color-badge {
  display: inline-block;
  line-height: 20px;
  color: #fff;
  padding: 0 10px;
  border-radius: 10px;
  background: #faa72c;
}
.report-state {
  @include flex-yc;
  .badge {
    margin-right: 5px;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    background: #333;
  }
}
.file-image-list {
  ::v-deep {
    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 4px;
    }
  }
}
.autocomplete-item {
  &-label {
    padding: 5px 0;
    line-height: 1;
  }
  &-value {
    font-size: 12px;
    color: #999;
    line-height: 1;
    padding-bottom: 5px;
  }
}
.actions-btn {
  display: flex;
  ::v-deep {
    .el-popover__reference {
      .el-button {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
.more-filter {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 4px;
  background: #f8f8f8;
  margin-bottom: 20px;
  ::v-deep {
    .el-form-item {
      width: 25%;
      &:last-child {
        width: 50%;
      }
      .el-input__inner {
        display: block;
        max-width: 200px;
      }
    }
  }
}
.filter-bar {
  @include flex-ycb;
  .filter {
    display: flex;
    & > * {
      margin-right: 10px;
    }
    .group {
      display: flex;
      ::v-deep {
        .el-select {
          width: 150px;
          .el-input__inner {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .el-range-editor {
          width: 240px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
.table {
  margin: 10px 0 20px 0;
}
.visit-code-btn {
  padding: 0 5px;
  position: relative;
  ::v-deep {
    .el-image {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      &__error {
        font-size: 12px;
      }
      &__inner {
        opacity: 0;
      }
    }
  }
}
</style>
